import API from "../apiConection";
import { createHeader } from "../KEYS";

export async function postService(data, token) {
  const header = createHeader(token);
  try {
    const res = await API.post("/services", data, header);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
export async function updateService(id,data, token) {
  const header = createHeader(token);
  try {
    const res = await API.put(`/services/${id}`, data, header);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * available puede ser: "", "/available","/noavailable"
 */
 export async function allServiceByType(
  categoryId,
  token,
  available = "",
  page = 0,
  size
) {
  const header = createHeader(token);
  
  try {
    const res = await API.get(
      `/services/type${available}/${categoryId}?page=${page}&size=${size}&order_by=created_at&order_direction=desc`,
      header
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteServiceById(id, token) {
  const header = createHeader(token);
  try {
    const res = await API.delete(`/services/${id}`, header);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getServiceById(id, token) {
  const header = createHeader(token);
  try {
    const res = await API.get(`/services/${id}`, header);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function allServicesPublic(categoryId,page,size=12) {
  try {
    const res = await API.get(
      `/services/type/a/${categoryId}?page=${page}&size=${size}&order_by=created_at&order_direction=desc`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getServiceByCode(code) {

  try {
    const res = await API.get(`/services/c/${code}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

/**IMAGES */

export async function sendImages(id, formData, token) {
    const config = {
      headers: { "x-access-token": token, "Content-Type": "multipart/form-data" },
    };
  
    try {
      const res = await API.post(
        "/services/images/upload/" + id,
        formData,
        config
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  export async function getNamesImage(id, token) {

    try {
      const res = await API.get(`/services/images/names/${id}`);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }
  export async function deleteImageByPublicname(publicname, token) {
    const header = createHeader(token);
    try {
      const res = await API.delete(`/services/images/${publicname}`, header);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }