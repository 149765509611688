import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import "./SwitDescription.css";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles({

    cardStyle:{
        marginTop:'-600px',
        zIndex:3,
        backgroundColor: 'rgba(0,0,0,0.1)',
        color:"#ffff",
        display:"flex",
        
        width:"100%",
    },
    content:{
        //backgroundColor: 'rgba(0,0,0,0.2)',
        height:'390px',
        display:"flex",
        justifyContent:"center",
        width:"100%",
        //boxShadow: "0px 0px 8px 8px rgba(0,0,0,0.2)"
       
    },
    card:{
        backgroundColor: 'rgba(0,0,0,0.5)',
        alignSelf:"center",
        textAlign: 'center',
        marginTop:"50px",
        padding: "0px 30px 30px 30px",
        borderRadius:"10px",
        maxWidth: "800px",
        //background:"red"
        
    },
    title:{
        fontSize:"60px",
        fontFamily: "Arial Black",
        fontWeight:"bold",
        textShadow: "1px 2px #333",
        ['@media screen and (max-width:1050px)']: {
            fontSize:"50px",
        },
        ['@media screen and (max-width:900px)']: {
            fontSize:"40px",
        },
        ['@media screen and (max-width:600px)']: {
            fontSize:"40px",
        },
        
       
    },

    description:{
        fontSize:"25px",
        textShadow: "1px 2px #595959",
        textAlign: 'center',
        ['@media screen and (max-width:1050px)']: {
            fontSize:"20px",
        },
        ['@media screen and (max-width:900px)']: {
            fontSize:"18px",
        },
        ['@media screen and (max-width:600px)']: {
            fontSize:"15px",
        },
    },
    btnMore:{
        /* background: "linear-gradient(90deg, #3DF139 0%, #07BB05 100%)", */
        background: 'linear-gradient(225deg, #22c33f 80%, #fff 10%)',
        color: "#fff",
        "&:hover": {
            background: 'linear-gradient(180deg, #22c33f 90%, #fff 5%)',
        },
        width:"150px" 
    }
    
})

export default function SwitDescription({step}) {
    const history = useHistory();
    const classes = useStyles();

    const redired = () =>{
        history.push(`${step.to}`);
    }
    return (
    
        <Box className={classes.cardStyle} >
            <div className={classes.content}>
                
                <div className={classes.card}>
                    <div id="titleDescriptionStart" className={classes.title}> {step.title}</div>
                    
                    <p className={classes.description}>{step.description}</p>
                    <Button 
                        className={classes.btnMore}
                        variant="contained"
                        onClick={redired}
                        sx={{color: "#fff"}}
                    >
                        Ver más
                        
                    </Button>
                </div>
            </div>
        </Box>
    );
}
