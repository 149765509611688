import React,{useContext} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Logo from '../../../../components/appbar/Logo';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UserContext from '../../../../contex/user/UserContext';
import { useForm, Controller } from "react-hook-form";
import {useHistory} from 'react-router-dom';
import Copyright from '../../Copyright';
import {API_URL} from '../../../../service/KEYS';



const theme = createTheme();


export default function SignInSide({toggleLoginDrawerClose}) {
  const {signin,message,setMessage,setAdminArea,isAdmin } = useContext(UserContext);
  let history = useHistory();
  const { handleSubmit, control,formState: { errors } } = useForm();
  
  const onSubmit = async(data)=>{
    const res = await signin(data);
    if(!res){
      setMessage("");
      toggleLoginDrawerClose();
      if(isAdmin){
        setAdminArea(true);
        history.push('/admin/welcome');
      }
      
    }
  }
  const closeLogin = ()=>{
    toggleLoginDrawerClose();
    setMessage("");
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${API_URL}/media/images/login)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Logo/>
            <Avatar sx={{ m: 1, background:"#44AA00" }}>
              <LockOutlinedIcon />
            </Avatar>
            <span style={{color:'red'}}>{message}</span>
            <Box sx={{ mt: 1 }}>
              <br/>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <Controller
                    name="username"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <TextField
                        label="Nombre de usuario"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                        helperText={error ? error.message : null}
                        type="text"
                        //id="input-with-sx" 
                        variant="standard" 
                        fullWidth
                        autoFocus
                      />
                    )}
                    rules={{ required: true }}
                  />
                </Box>
                  <span style={{color:'red'}}>{errors.password?.type === 'required' && "Ingrese nombre de usuario"}</span>
                <br/>
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                  <VpnKeyIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                  <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Contraseña"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                          fullWidth
                          type="password"
                          autoComplete="current-password"
                          variant="standard" 
                          //id="input-with-sx"
                        />
                      )}
                      rules={{ required: true }}
                    />
                </Box>
                    <span style={{color:'red'}}>{errors.password?.type === 'required' && "Ingrese contraseña"}</span>
              
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, background:"#44AA00",":hover": {
                        background: "#2C6F00",
                      }
                    }}
                    
                  >
                    Iniciar sesión 
                  </Button>
              </form>
              <Grid container>
                <Grid item xs>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={closeLogin}
                    color="secondary"
                  >
                    Cancelar
                  </Button>
                </Grid>

              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}