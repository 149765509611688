import React, { Fragment } from "react";
import NavFooter from "./NavFooter";
import Contact from "./Contact";
import { Container } from "@mui/material";
import InformationBusiness from "../business/Informations";
import BtnFlotante from "../../../components/general/BtnFlotante";
import MapView from '../geolocation/MapView'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Footer = () => {
  return (
    <Fragment>
      <BtnFlotante />
      <Container>
        <InformationBusiness />
        <Grid mt={2}>
          <Paper sx={{ p: 2 }} >
            <MapView />
          </Paper>
        </Grid>
      </Container>
      <Grid mt={2}>
        <Contact />
      </Grid>
      <Grid>
        <NavFooter />
      </Grid>
    </Fragment>
  );
};

export default Footer;
