import React, { useEffect, useState } from "react";
import Facebook from "../../assets/redesSociales/facebook.png";
import Instagram from "../../assets/redesSociales/instagram.png";
import Tiktok from "../../assets/redesSociales/tik-tok.png";
import WhatsApp from "../../assets/redesSociales/whatsapp.png";
import Youtube from "../../assets/redesSociales/youtube.png";
import AddIcon from "@mui/icons-material/Add";
import "./BtnFlotante.css";
import { getContactInfo } from "../../service/api/ContactService";

export default function BtnFlotante() {
  const [contact, setContact] = useState({});

  useEffect(() => {
    const initial = async () => {
      const res = await getContactInfo();
      setContact(res);
    };
    initial();
  }, []);
  return (
    <div className="containerBtn">
      <input type="checkbox" id="btn-mas"></input>
      <div className="redes">
        <a
          href={contact.facebook}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Facebook} alt="facebook"></img>
        </a>
        <a
          href={contact.instagram}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Instagram} alt="instagram"></img>
        </a>
        <a
          href={contact.tiktok}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Tiktok} alt="tiktok"></img>
        </a>
        <a
          href={contact.whatsapp}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          <img src={WhatsApp} alt="whatsapp"></img>
        </a>
        <a
          href={contact.youtube}
          underline="none"
          target="_blank"
          rel="noreferrer"
        >
          <img src={Youtube} alt="youtube"></img>
        </a>
      </div>
      <div className="btn-mas">
        <label htmlFor="btn-mas" className="icon-mas2">
          <AddIcon sx={{ color: "#ffff" }} />
        </label>
      </div>
    </div>
  );
}
