import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import DrawerContent from './DrawerContent';

export default function TemporaryDrawer({state,toggleDrawer,toggleDrawerLogin}) {
  

  return (
    <React.Fragment >
          
          <Drawer
            
            open={state}
            onClose={toggleDrawer( false)}
          >
            <Box
              sx={{ width: 225 }}
              role="presentation"
              //onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <DrawerContent toggleDrawer={toggleDrawer} toggleDrawerLogin={toggleDrawerLogin} />
            </Box>
          </Drawer>
      </React.Fragment>
  );
}
