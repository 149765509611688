import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { makeStyles } from "@mui/styles";
import AlarmIcon from '@mui/icons-material/Alarm';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from '@mui/material/Link';
import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';

import TextareaAutosize from '@mui/material/TextareaAutosize';


import Tiktok from '../../../assets/redesSociales/tik-tok.png'
import { getContactInfo } from '../../../service/api/ContactService';
const useStyles = makeStyles({
    contect: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "-100px",

    },
    title: {
        textAlign: 'center',
        fontWeight: "bold",

    }
})

const Contact = () => {
    const classes = useStyles();
    const [contact, setContact] = useState({});
    const [message, setMessage] = useState("");

    const contentMessage = (e) => {
        setMessage(e.target.value);

    }
    useEffect(() => {

        const initial = async () => {
            const res = await getContactInfo();
            setContact(res);
        }
        initial();
    }, [])
    return (
        <div id="contacto-smartplants" className={classes.contect}>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',

                    '& > :not(style)': {
                        m: 2,
                        maxWidth: '1000px',
                        borderRadius: "40px"
                    },
                }}
            >
                <Paper elevation={24}>
                    <Typography sx={{ textAlign: 'center', fontWeight: "bold", color: '#2f3542', marginTop: "25px" }} className={classes.title} variant="h4" component="h1">
                        Contactanos
                    </Typography>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={4} md={4}>
                            <List
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Datos de contacto
                                    </ListSubheader>
                                }
                            >
                                <ListSubheader component="div" id="nested-list-subheader">
                                    <Typography >
                                        OFICINA CENTRAL
                                    </Typography>
                                </ListSubheader>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LocationOnIcon sx={{ color: "#c0392b" }} />
                                    </ListItemIcon>
                                    
                                    <Link href={contact.location} underline="none" target="_blank" rel="noreferrer">
                                        Calle  Honduras N°630  entre Av. Republica y  Lanza, Edificio Honduras,  primer piso, Oficina Comercial N°16
                                    </Link>
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <WhatsAppIcon sx={{ color: "#2ecc71" }} />
                                    </ListItemIcon>
                                    <Link href={contact.whatsapp} underline="none" target="_blank" rel="noreferrer">
                                        +591 72260717
                                    </Link>
                                </ListItemButton>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <MailIcon sx={{ color: "#8e44ad" }} />
                                    </ListItemIcon>
                                    <Link href='mailto:plantsbolivia@gmail.com' underline="none">
                                        plantsbolivia@gmail.com
                                    </Link>
                                </ListItemButton>
                            </List>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <List
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Consultanos por WhatsApp
                                    </ListSubheader>
                                }
                            >
                                <div style={{ margin: "0px 10px" }}>
                                    <TextareaAutosize
                                        onChange={contentMessage}
                                        aria-label="minimum height"
                                        minRows={4}
                                        placeholder="Escribenos"
                                        style={{ width: "100%", letterSpacing: "1px", border: '2px solid #27ae60', outline: 'none', borderRadius: "10px" }}
                                    />
                                    <Button sx={{ textTransform: 'none', color: "#27ae60", marginLeft: "70%" }} target="_blank" href={`https://api.whatsapp.com/send?phone=${contact.whatsapp}&text=${message}`}>Enviar <SendIcon sx={{ color: "#27ae60", marginLeft: "10px" }} /></Button>

                                </div>
                            </List>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <List
                                sx={{ width: '100%', bgcolor: 'background.paper' }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                subheader={
                                    <ListSubheader component="div" id="nested-list-subheader">
                                        Siguenos en nuestras redes sociales
                                    </ListSubheader>
                                }
                            >
                                <div style={{ display: 'flex', justifyContent: "space-around" }}>
                                    <Link href={contact.facebook} underline="none" target="_blank" rel="noreferrer">
                                        <FacebookIcon sx={{ color: "#0984e3", fontSize: "30px" }} />
                                    </Link>
                                    <Link href={contact.youtube} underline="none" target="_blank" rel="noreferrer">
                                        <YouTubeIcon sx={{ color: "#d63031", fontSize: "30px" }} />
                                    </Link>
                                    <Link href={contact.tiktok} underline="none" target="_blank" rel="noreferrer">
                                        <img alt="imagen" src={Tiktok} sx={{ color: "#e84393", fontSize: "50px" }} style={{ heigth: "40px" }}></img>
                                    </Link>
                                    <Link href={contact.instagram} underline="none" target="_blank" rel="noreferrer">
                                        <InstagramIcon sx={{ color: "#e84393", fontSize: "30px" }} />
                                    </Link>
                                </div>

                            </List>
                           
                                <ListSubheader >
                                    <Typography >
                                        HORARIOS DE ATENCIÓN
                                    </Typography>
                                    <p>
                                     Lunes a Viernes de 09:00 - 18:00
                                    </p>
                                   
                                    <p>
                                      Sábado de 09:00 - 14:00
                                    </p>
                                </ListSubheader>
                           
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </div>
    )
}

export default Contact
