import React, { useContext, lazy, Suspense } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import UserRoutes from "./router/User.routes";
import AppBarUser from "./components/appbar/AppBar";
import "./App.css";

import UserContext from "./contex/user/UserContext";

const AdminDefaultLayout = lazy(() => import("./layout/AdminDefaultLayout"));

const loading = (
  <div className="content-loading">
    <div className="loader"></div>
  </div>
);


function App() {
  const { adminArea, isAdmin } = useContext(UserContext);

  
  return (
    <HashRouter>
      <Suspense fallback={loading}>
        {!adminArea && <AppBarUser />}
        <Switch>
          {isAdmin && (
            <Route
              path="/admin"
              name="admin"
              render={(props) => <AdminDefaultLayout {...props} />}
            />
          )}
          <UserRoutes />
        </Switch>
      </Suspense>
    </HashRouter>
  );
}

export default App;
