import {API_URL} from '../../../service/KEYS';

const Option = [
    {
      key:"1",
      imgLg:`${API_URL}/media/images/lg/Invernadero.jpg`,
      imgMd:`${API_URL}/media/images/md/Invernadero.jpg`,
      imgSm:`${API_URL}/media/images/sm/Invernadero.jpg`,
      title:"INVERNADEROS",
      to:"/all/invernaderos",
      description:"Venta de materiales y accesorios para la construcción de invernaderos metálicos modernos. Diseño y construcción de invernaderos tecnificados según el requerimiento de cultivo."
    },
    {
      key:"2",
      imgLg:`${API_URL}/media/images/lg/Maquinaria.jpg`,
      imgMd:`${API_URL}/media/images/md/Maquinaria.jpg`,
      imgSm:`${API_URL}/media/images/sm/Maquinaria.jpg`,
      title:"MAQUINARIAS",
      to:"/all/maquinarias",
      description:"Venta al por mayor y menor de maquinaria y equipo agropecuarios. Amplia experiencia de trabajo con proyectos, alcaldías, gobernaciones, proyectos privados."
  
    },
    {
      key:"3",
      imgLg:`${API_URL}/media/images/lg/Laboratorio.jpg`,
      imgMd:`${API_URL}/media/images/md/Laboratorio.jpg`,
      imgSm:`${API_URL}/media/images/sm/Laboratorio.jpg`,
      title:"LABORATORIOS",
      to:"/all/laboratorios",
      description:"Venta al por mayor y menor de equipos e instrumentos de laboratorio. Asesoría técnica en la implementación de laboratorios de biotecnología, fitopatología y suelos."
    },
    {
      key:"4",
      imgLg:`${API_URL}/media/images/lg/Riego.jpg`,
      imgMd:`${API_URL}/media/images/md/Riego.jpg`,
      imgSm:`${API_URL}/media/images/sm/Riego.jpg`,
      title:"RIEGOS",
      to:"/all/riegos",
      description:"Venta de equipamiento de riego tecnificado de calidad. Brindamos asesoramiento en automatización, diseño, instalación, puesta en marcha y evaluación de los sistemas de riego tecnificado agrícola."
  
      },
    
  ];

export default Option
