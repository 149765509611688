import React, { Fragment, useContext } from "react";
import LoginIcon from "@mui/icons-material/Login";
import Button from "@mui/material/Button";
import UserContext from "../../contex/user/UserContext";
import {useHistory} from "react-router-dom";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const BtnLogin = ({ toggleDrawerLogin }) => {
    const history = useHistory();
  const { online,isAdmin } = useContext(UserContext);

  const redirect=()=>{
      history.push("/admin/welcome");
  }
  return (
    <Fragment>
      {!online && (
        <Button onClick={toggleDrawerLogin(true)} sx={{ color: "#ffff" }}>
          <LoginIcon />
        </Button>
      )}
      {isAdmin && (
        <Button onClick={redirect} sx={{ color: "#ffff" }}>
          <AdminPanelSettingsIcon sx={{fontSize:"40px"}}/>
        </Button>
      )}
    </Fragment>
  );
};

export default BtnLogin;
