import React,{useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useHistory} from 'react-router-dom';


export default function BasicMenu({option}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [expand,setExpand] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setExpand(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setExpand(false);
  };

  const redirect = (item) =>{
    history.push({
      pathname: item.to,
      state: item.id,
    });
  }

  return (
    <>
      <MenuItem
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{"&:hover": {
            backgroundColor: "#4caf50",
            cursor: "pointer",
          }
        }}
        
      >
        {option.icon}
        {option.name}
        {!expand&& <ExpandMoreIcon sx={{fontSize:"30px", }}/>}
        {expand&& <ExpandLessIcon sx={{fontSize:"30px"}}/> }
      </MenuItem>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        
      >
        {
            option.items.map((item)=>(
                <MenuItem 
                sx={{"&:hover": {
                  backgroundColor: "#bdbdbd",
                  cursor: "pointer",
                }
              }}
                key={item.name} 
                onClick={()=>{handleClose();redirect(item)}}
                >{item.name}</MenuItem>
            ))
        }
      </Menu>
    </>
  );
}