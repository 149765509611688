import Box from "@mui/material/Box";
import React, { Fragment } from "react";
import MenuItem from "@mui/material/MenuItem";
import Items from "./Items";
import {_nav as nav} from "./_navUser";
import { scrollId } from "../../service/utils/funtions.js";
import { useHistory } from "react-router-dom";

const Options = () => {
  const history = useHistory();
  return (
    <Box sx={{ flexGrow: 1, display: { xs: "flex" } }}>
      {nav.map((option) => (
        <Fragment key={option.name}>
          {!option.id && (
            <>
              {!option.items && (
                <MenuItem
                  key={option.name}
                  onClick={() => {
                    history.push(option.to);
                  }}
                  sx={{"&:hover": {
                    backgroundColor: "#4caf50",
                    cursor: "pointer",
                  }
                }}
                >
                  {option.icon}
                  {option.name}
                </MenuItem>
              )}
              {option.items && <Items option={option} />}
            </>
          )}
          {option.id && (
            <MenuItem 
            sx={{"&:hover": {
              backgroundColor: "#4caf50",
              cursor: "pointer",
            }
          }}
            key={option.name} onClick={() => scrollId(option.to)}>
              {option.icon}
              {option.name}
            </MenuItem>
          )}
        </Fragment>
      ))}
    </Box>
  );
};

export default Options;
