import React from 'react';
import LogoIcon from '../../assets/LogoIcon.png';
import LogoText from '../../assets/LogoText.png';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    styleIcon:{
        width:"100px",
        /* ['@media screen and (max-width:555px)']: {
            width: "80px",
          }, */
    },
    styleText:{
        width:"180px",
        /* ['@media screen and (max-width:555px)']: {
            width: "150px",
          }, */
    }

  });


const Logo = ({widthIcon="100px",widthText="180px"}) => {
    const classes = useStyles();
    return (
        <div style={{display:"flex", alignItems:'center',marginInline: "20px"}}>
            <img src={LogoIcon} className={classes.styleIcon} alt="imagenes"/>
            <img src={LogoText} className={classes.styleText} alt="imagenes"/>
        </div>
    )
}

export default Logo
