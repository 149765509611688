import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BusinessIcon from '@mui/icons-material/Business';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
export const CATEGORY_PRODUCTS =  [
  {
      name: 'Invernaderos',
      to: '/all/invernaderos',
      id: 2
  },
  {
      name: 'Maquinarias y equipos',
      to: '/all/maquinarias',
      id:1
  },
  {
    name: 'Laboratorios',
    to: '/all/laboratorios',
    id:4
  },
  {
      name: 'Riegos',
      to: '/all/riegos',
      id:3
  },
];
export const _nav = [
  {
    name: 'Inicio',
    to: '/',
    id:false,
    icon:<HomeIcon style={{marginTop:"-7px"}}/>
  },
  {
    name: 'Empresa',
    to:'business-smartplants',
    id:true,
    icon:<BusinessIcon style={{marginTop:"-7px"}}/>
  },
  {
    name: 'Productos',
    id:false,
    icon:<ProductionQuantityLimitsIcon style={{marginTop:"-7px"}}/>,
    items: CATEGORY_PRODUCTS,
    },
    {
      name: 'Servicios',
      id:false,
      icon:<DesignServicesIcon style={{marginTop:"-7px"}}/>,
      items: [
              {
                name: 'Diseño y construcción de invernaderos',
                to: '/service/construnccion',
                id: 1
              },
              {
                name: ' Diseño e instalación de sistemas de riego',
                to: '/service/instalacion',
                id:2
              },
              {
                name: 'Cursos de capacitación',
                to: '/service/cursos',
                id:3
              },
              {
                name: 'Asesoría técnica en la producción ',
                to: '/service/asesorias',
                id:4
              },
              {
                name: 'Laboratorio agrícola ',
                to: '/service/laboratorio',
                id:5
              },
          ],
      },
    {
        name: 'Contacto',
        to:'contacto-smartplants',
        id:true,
        icon:<LocalPhoneIcon style={{marginTop:"-7px"}}/>
    },
 
  
]



