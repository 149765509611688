import React from "react";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles({
  contect: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "-100px",
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
  },
  subtitle: {
    fontWeight: "bold",
    color: "#2f3542",
    marginTop: "25px",
  },
  negrita: {
    fontWeight: "bold",
    color: "#2f3542",
    marginTop: "20px",
  },
});
const Informations = () => {
  const classes = useStyles();
  return (
    <div id="business-smartplants" style={{ transition: ".5s ease" }}>
      <Typography
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#2f3542",
          marginTop: "25px",
        }}
        className={classes.title}
        variant="h4"
        component="h1"
      >
        Empresa
      </Typography>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{ fontWeight: "bold" }}
            className={classes.subtitle}
            variant="h5"
            component="h1"
          >
            ¿Quienes somos?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <strong>SMARTPLANTS</strong>, somos una empresa boliviana, especializada
          en el desarrollo de soluciones tecnológicas aplicadas a la agricultura
          y agroindustria de precisión. La empresa trabaja bajo normas de
          calidad y una política de mejora continua.
          <p></p>
          <p></p>
          Nuestra empresa surge como un referente en la aplicación de
          tecnologías de agricultura de precisión en el agro boliviano, esto
          contribuirá en el
          <p></p>
          éxito de los productores del mundo agrícola con soluciones
          tecnológicas aplicadas de forma inteligente y oportuna para cada
          cliente en particular;
          <p></p>
          permitiéndose alcanzar sus metas productivas.
          <p></p>
          Nuestra meta es proyectarnos como la empresa mas importante del país y
          referente de tecnología aplicada al mundo agrícola.
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Informations;
