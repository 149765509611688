import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { API_URL, APP_EXPO } from "../../../service/KEYS";
import { getNamesImage } from "../../../service/api/productService";
import "./CardStyle.css";
import DialogImages from "../../../components/general/DialogImages";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { getContactInfo } from "../../../service/api/ContactService";
import { setClipboardText } from "../../../service/utils/funtions";

import LinkIcon from "@mui/icons-material/Link";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";

const outerTheme = createTheme({
  palette: {
    secondary: {
      main: "#22c33f",
    },
  },
});

export default function CardProduct({ product }) {
  const history = useHistory();

  const [namesImage, setNamesImage] = useState([]);
  const [openDialogImages, setOpenDialogImages] = useState(false);
  const [contact, setContact] = useState({});
  const [viewDialogImages, setViewDialogImages] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");

  const requestNamesImage = async () => {
    try {
      const res = await getNamesImage(product.id);
      setNamesImage(res);
      setViewDialogImages(true);
      setOpenDialogImages(true);
    } catch (error) {}
  };
  const copyLinkProduct = () => {
    setClipboardText(`${APP_EXPO}/shared/product/${product.code}`);
  };
  const viewProductShared = () => {
    history.push(`/shared/product/${product.code}`);
  };
  useEffect(() => {
    const initial = async () => {
      const res = await getContactInfo();
      setContact(res);
    };
    initial();
  }, []);
  return (
    <Card
      key={product.id}
      sx={{
        maxWidth: 300,
        borderRadius: "7px",
        boxShadow: "2px 5px 5px #999",
        transition: "all 400ms ease",
      }}
      className="cardProduc"
    >
      <Typography
        variant={matches ? "subtitle1" : "h6"}
        sx={{ padding: "10px", color: "#616161" }}
        component="div"
        fontWeight="bold"
        fontFamily="unset"
      >
        {product.name}{" "}
      </Typography>
      {viewDialogImages && (
        <DialogImages
          open={openDialogImages}
          images={namesImage}
          title={product.name}
          description={product.description}
          setOpen={setOpenDialogImages}
          setViewDialogImages={setViewDialogImages}
        />
      )}
      <div onClick={requestNamesImage} className="card-media-product">
        <Box
          component="img"
          src={`${API_URL}/products/images/300/${product.default_image}`}
          alt="imagen producto"
          sx={{
            width: "100%",
            height: {
              md: "22vh",
              lg: "25vh",
            },
            objectFit: "contain",
          }}
        />
        <div className="card-media-content-btn">
          <h5>ver más</h5>
        </div>
      </div>
      <CardActions
        disableSpacing
        sx={{
          height: "35px",
          display: "flex",
          justifyContent: "space-around",
          marginTop: "5px",
        }}
      >
        {product.price_id.visible === true && (
          <span
            style={{
              padding: "2px",
              borderRadius: "50%",
              fontWeight: "bold",
              color: "#004d40",
              fontSize: "12px",
            }}
          >
            {product.price_id.totalPrice} Bs.
          </span>
        )}
        <Tooltip title="Copiar link del producto">
          <IconButton onClick={copyLinkProduct} aria-label="share">
            <LinkIcon sx={{ color: "#3498db" }} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Ver fotos">
          <IconButton onClick={requestNamesImage} aria-label="share">
            <PhotoLibraryIcon sx={{ color: "#8c7ae6" }} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Ver producto">
          <IconButton onClick={viewProductShared} aria-label="share">
            <RemoveRedEyeIcon sx={{ color: "#273c75" }} />
          </IconButton>
        </Tooltip>
      </CardActions>

      <CardContent sx={{ padding: "8px" }}>
        {/* <Typography sx={{ textAlign: "justify" }} variant="body2">
          <Description descrip={product.description} longDescription={140} />
        </Typography> */}
      </CardContent>
      <ThemeProvider theme={outerTheme}>
        <Button
          className="btn-pedido"
          color="secondary"
          variant="contained"
          sx={{ color: "#fff" }}
          disableElevation
          target="_blank"
          href={`https://api.whatsapp.com/send?phone=${contact.phone}&text=${product.name}`}
        >
          Realizar pedido <ShoppingCartIcon />
        </Button>
      </ThemeProvider>
    </Card>
  );
}
