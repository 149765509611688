import React, { useState, useEffect, useContext, Fragment } from "react";
import Footer from "../footer/Footer";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PaginationContext from "../../../contex/servicePagination/PaginationContext";
import { allServicesPublic } from "../../../service/api/serviceService";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import {scrollId} from "../../../service/utils/funtions";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Typography } from '@mui/material';
import CardService from "./CardService";

const ServicesViews = () => {
  const [services, setServices] = useState([]);
  const [nameService, setNameService] = useState("");
  const location = useLocation();
  const [statusProgress, setStatusProgress] = useState(true);
  const { numPage, updateNumPage, total, updateTotal } =
    useContext(PaginationContext);

  const onChangePagination = (event, page) => {
    updateNumPage(page - 1);
    scrollId("prodtucts-help-scroll-top");
  };

  useEffect(() => {
    const initial = async () => {
      try {
        var res;
        var actualLoacation = 0;
        switch (location.pathname) {
          case "/service/construnccion":
            actualLoacation = 1;
            setNameService("Diseño y construcción de invernaderos")
            break;
          case "/service/instalacion":
            actualLoacation = 2;
            setNameService("Diseño e instalación de sistemas de riego")
            break;
          case "/service/cursos":
            actualLoacation = 3;
            setNameService("Cursos de capacitación")
            break;
          case "/service/asesorias":
            actualLoacation = 4;
            setNameService("Asesoria técnica en la producción")
            break;
          case "/service/laboratorio":
            actualLoacation = 5;
            setNameService("Laboratorio agrícola")
            break;
          default:
            actualLoacation = 0;
        }
        setStatusProgress(true);
        if (location.state !== undefined) {
          res = await allServicesPublic(location.state, numPage, 12);
        } else {
          res = await allServicesPublic(actualLoacation, numPage, 12);
        }

        setServices(res.content);
        updateTotal(res.totalPages);
        setStatusProgress(false);
      } catch (error) {

      }
    };
    initial();
  }, [numPage, location.state, location.pathname, updateTotal]);
  return (
    <Fragment>
      <div id="prodtucts-help-scroll-top" style={{ background: "#cfe8fc" }}></div>
      <Grid
        sx={{ bgcolor: "#eeeeee", width: "100%" }}
        container
        alignItems="center"
        padding={3}
        rowSpacing={3}
        columns={12}
        columnSpacing={{ xs: 1, sm: 1, md: 1, mt: 2 }}
      >
        <Grid item xs={12} sm={4} md={12} margin={2} align="center">
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#2f3542",
             
            }}
            variant="h4"
            component="h1"
          >
            {nameService}
          </Typography>
        </Grid>

        {statusProgress&&<Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>}
        {services.map((service, index) => (
          <Grid key={index} align="center" item xs={12} sm={6} md={6}>
            <CardService key={index} service={service} />
          </Grid>
        ))}
        <Grid item md={12} align="center">
          <Stack spacing={2}>
            <Pagination
              sx={{}}
              onChange={onChangePagination}
              count={total}
              color="primary"
            />
          </Stack>
        </Grid>
      </Grid>

      <Footer />
    </Fragment>
  );
};

export default ServicesViews;
