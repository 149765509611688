import React, {useContext, useState } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LoginIcon from "@mui/icons-material/Login";
import { scrollId } from "../../service/utils/funtions.js";
import {_nav as Options} from "./_navUser";
import LogoAdmin from "../LogoAdmin"
import { makeStyles } from "@mui/styles";
import { useHistory } from 'react-router-dom';
import UserContext from "../../contex/user/UserContext";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const useStyles = makeStyles({
  styleDivider: {
    color: "#44AA00",
  },
});


const DrawerContent = ({ toggleDrawerLogin, toggleDrawer }) => {
  const [open, setOpen] = useState(true)
  const classes = useStyles();
  const history = useHistory();
  const { online, isAdmin } = useContext(UserContext);
  const redirect = (item) => {
    
    history.push({
      pathname: item.to,
      state: item.id,
    });
  }

  const redirectAdmin = () => {
    history.push("/admin/welcome");
  }

  return (
    <div>
      <div style={{ margin: "5px" }}>
        <LogoAdmin />
      </div>

      <Divider className={classes.styleDivider} />
      <List>
        {Options.map((option, index) => (
          <div key={index}>
            {!option.id && (
              <ListItem button key={option.name} onClick={()=>{history.push(option.to)}} sx={{color:"#1976d2"}}>
                <ListItemIcon sx={{color:"#1976d2"}}>{option.icon}</ListItemIcon>
                <ListItemText primary={option.name} />
              </ListItem>
            )}
            {option.id && (
              <ListItem button key={index} sx={{color:"#1976d2"}}>
                <ListItemIcon sx={{color:"#1976d2"}}>{option.icon}</ListItemIcon>
                <ListItemText
                  onClick={() => {
                    scrollId(option.to);
                    toggleDrawer(false);
                  }}
                  primary={option.name}
                />
              </ListItem>
            )}
            {option.items && (
              <List key={index} component="div" sx={{ pl: 8 }}>
                {option.items.map((item,index) => {
                  return (
                    <ListItem key={index} button onClick={() => { redirect(item) }} >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  );
                })}
              </List>
            )}
          </div>
        ))}
      </List>
      <Divider className={classes.styleDivider} />
      {!online && (
        <ListItem button onClick={toggleDrawerLogin(true)} sx={{color:"#1976d2"}}>
          <ListItemIcon>
            <LoginIcon sx={{color:"#1976d2"}} />
          </ListItemIcon>
          <ListItemText primary="Ingresar" />
        </ListItem >
      )}
      {isAdmin && (
        <ListItem button onClick={redirectAdmin} sx={{color:"#1976d2"}}>
          <ListItemIcon>
          <AdminPanelSettingsIcon sx={{color:"#1976d2"}} />
          </ListItemIcon>
          <ListItemText  primary="Panel de administración"/>
        </ListItem>
      )}
      <Divider className={classes.styleDivider} />
    </div>
  );
};

export default DrawerContent;
