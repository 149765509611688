import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { IconLocation } from "./IconLocation";
import "leaflet/dist/leaflet.css";

function MapView() {
  const state = {
    currentLocation: { lat: "-17.40130", lng: "-66.15194" },
    zoom: 18,
  };
  return (
    <div>
    <MapContainer
      center={state.currentLocation}
      zoom={state.zoom}
      style={{
        margin: "auto",
        maxWidth: 1100,
        flexGrow: 1,
        height: 300,
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        zIndex: 0
      }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={[-17.4013, -66.15194]} icon={IconLocation}>
        <Popup>
          <strong>SMARTPLANTS</strong> <br /> C. Honduras N°630 entre <br />
          C. Lanza y Av. República.
        </Popup>
      </Marker>
    </MapContainer>
    </div>
  );
}

export default MapView;
