import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { API_URL, APP_EXPO } from "../../../service/KEYS";
import { getNamesImage } from "../../../service/api/serviceService";
import "./CardStyle.css";
import DialogImages from "../../../components/general/DialogImages";
import Button from "@mui/material/Button";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { getContactInfo } from "../../../service/api/ContactService";
import { setClipboardText } from "../../../service/utils/funtions";

import LinkIcon from "@mui/icons-material/Link";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";


export default function CardService({ service }) {
  const history = useHistory();
  
  const [namesImage, setNamesImage] = useState([]);
  const [openDialogImages, setOpenDialogImages] = useState(false);
  const [viewDialogImages, setViewDialogImages] = useState(false);
  const [contact, setContact] = useState({});

  const requestNamesImage = async () => {
    try {
      const res = await getNamesImage(service.id);
      setNamesImage(res);
      setViewDialogImages(true);
      setOpenDialogImages(true);
    } catch (error) {}
  };
  const copyLinkService = () => {
    setClipboardText(`${APP_EXPO}/shared/service/${service.code}`);
  };
  const viewServiceShared = () => {
    history.push(`/shared/service/${service.code}`);
  };
  useEffect(() => {
    const initial = async () => {
      const res = await getContactInfo();
      setContact(res);
    };
    initial();
  }, []);
  return (
    <Card sx={{ maxWidth: 550, borderRadius:"7px", 
    boxShadow: "2px 5px 5px #999", 
    transition:"all 400ms ease"}} className="cardProduc">
      <Typography variant="h5" sx={{padding:"10px", color:"#1976d2"}} component="div" fontWeight="bold" fontFamily="unset">
        {service.name}{" "}
      </Typography>
      {viewDialogImages&&<DialogImages
        open={openDialogImages}
        images={namesImage}
        title={service.name}
        setOpen={setOpenDialogImages}
        description= {service.description}
        setViewDialogImages={setViewDialogImages}
      />}
      <div
        onClick={() => {
          requestNamesImage();
        }}
        className="card-media-service"

      >
        <img src={`${API_URL}/services/images/300/${service.default_image}`} alt='producto' style={{ width: '100%', height: 355 }} />
        <div className="card-media-content-btn">
          <h5>ver más</h5>
        </div>
      </div>
      <CardActions
        disableSpacing
        sx={{ height: "35px", display: "flex", justifyContent: "space-around",marginTop:"5px" }}
      >
        {(service.price_id.visible === true)&&<span
          style={{
/*             background: "linear-gradient(90deg, #f1c40f 20%, #f39c12 100%)",
 */            padding: "4px",
            borderRadius: "50%",
            fontWeight: "bold",
            color: "#1976d2",
            fontSize: "20px",
          }}
        >
          {service.price_id.totalPrice} Bs.
        </span>}
        <Tooltip title="Copiar link del servicio">
          <IconButton onClick={copyLinkService} aria-label="share">
            <LinkIcon sx={{color:"#3498db"}}/>
          </IconButton>
        </Tooltip>

        <Tooltip title="Ver fotos">
          <IconButton onClick={requestNamesImage} aria-label="share">
            <PhotoLibraryIcon sx={{color:"#8c7ae6"}}/>
          </IconButton>
        </Tooltip>

        <Tooltip title="Ver servicio">
          <IconButton onClick={viewServiceShared} aria-label="share">
            <RemoveRedEyeIcon sx={{color:"#273c75"}}/>
          </IconButton>
        </Tooltip>
        
        
      </CardActions>

      

      <Button
        className="btn-pedido"
        variant="contained"
        sx={{color:"#fff"}}
        disableElevation
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=${contact.whatsapp}&text=${service.name}`}
      >
        Solicitar servicio <MiscellaneousServicesIcon/>
      </Button>
    </Card>
  );
}
