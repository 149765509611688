import React, { useEffect, Fragment } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import SwitDescription from "./SwitDescription";
import images from "./Option";
import { makeStyles } from "@mui/styles";

import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles({
  styleImg: {},
});

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepperMain() {
  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;
  const matches = useMediaQuery("(min-width:1000px)");
  const matchesMovil = useMediaQuery("(max-width:400px)");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  useEffect(() => {

  }, [matches,matchesMovil]);
  return (
    <Fragment>
      {!matchesMovil&&<Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={`key${index}`} style={{ height: "600px" }}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  key={index}
                  component="img"
                  sx={{
                    height: "100%",
                    display: "block",
                    overflow: "hidden",
                    width: "100%",
                    zIndex: -3,
                  }}
                  src={matches ? step.imgLg : step.imgMd}
                  alt={step.label}
                  className={classes.styleImg}
                />
              ) : null}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SwitDescription step={step} />
              </div>
            </div>
          ))}
        </AutoPlaySwipeableViews>

        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          }
        />
      </Box>}
      {matchesMovil&&<Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div key={step.label} style={{ height: "600px" }}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={{
                    height: "100%",
                    display: "block",
                    overflow: "hidden",
                    width: "100%",
                    zIndex: -3,
                  }}
                  src={step.imgSm}
                  alt={step.label}
                  className={classes.styleImg}
                />
              ) : null}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SwitDescription step={step} />
              </div>
            </div>
          ))}
        </AutoPlaySwipeableViews>

        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          }
        />
      </Box>}
    </Fragment>
  );
}

export default SwipeableTextMobileStepperMain;
