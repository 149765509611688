import React from 'react';
import LogoIcon from '../assets/LogoIcon.png';
import LogoText from '../assets/LogoText.png';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    styleIcon:{
        width:"70px",
        ['@media screen and (max-width:770px)']: {
            width: "55px",
          },
    },
    styleText:{
        width:"145px",
        ['@media screen and (max-width:770px)']: {
            width: "135px",
          },
    }

  });


const Logo = ({widthIcon="100px",widthText="180px"}) => {
    const classes = useStyles();
    return (
        <div style={{display:"flex", alignItems:'center',marginInline: "20px"}}>
            <img src={LogoIcon} className={classes.styleIcon} alt="imagen1"/>
            <img src={LogoText} className={classes.styleText} alt="imagen2"/>
        </div>
    )
}

export default Logo
