import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Options from './Options';
import BtnLogin  from './BtnLogin';
import Logo from './Logo';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Drawer from "./Drawer";
import LoginDrawer from '../../views/admin/pages/login/LoginDrawer'

/* #44AA00 #07BB05 */
const useStyles = makeStyles({
  appBarNavegation:{
    background: 'linear-gradient(45deg, #FFFF 30%, #FFFF 90%)',
    height: '70px'
  },
  divStylePrimary: {
    borderLeft: '70px solid transparent',
    borderTop: '70px solid #22c33f',
    width: '100%',
  },
  contentOptions:{
    position: 'relative',
    top:'-65px'
  },
  btnMenu:{
    
    marginLeft:"80%",
    marginTop:"-2px",

    ['@media screen and (max-width:700px)']: {
      marginLeft: "60%",
    },
    ['@media screen and (max-width:555px)']: {
      marginLeft: "30%",
    },
    ['@media screen and (max-width:498px)']: {
      marginLeft: "0px",
    },
    ['@media screen and (max-width:473px)']: {
      marginLeft: "-50px",
    },
    ['@media screen and (max-width:409px)']: {
      marginLeft: "-100px"
    },
  },
  menuStyle:{
    color:"#ffff",
    fontWeight: "bold",
    fontSize: "28px",
    ['@media screen and (max-width:409px)']: {
      color:"black",
    },
  }
  
});



function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};


export default function HideAppBar(props) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:965px)');
  const [stateDrawer, setStateDrawer] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    setStateDrawer(open);
  };
  
  const [stateDrawerLogin, setStateDrawerLogin] = React.useState(false);

    const toggleDrawerLogin = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
        }
        setStateDrawerLogin(open);
    };
    const toggleLoginDrawerClose = ()=>{
      setStateDrawerLogin(false);
    }
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar className={classes.appBarNavegation} sx={{display:'flex', flexDirection:'row'}}>
          <Logo />
          <div className={classes.divStylePrimary}>
            <Toolbar className={classes.contentOptions}>
              {matches&&<Options/>}
              {matches&&<BtnLogin toggleDrawerLogin={toggleDrawerLogin}/>}
              {!matches&&<div className={classes.btnMenu}><Button onClick={toggleDrawer(true)}><MenuIcon className={classes.menuStyle}/></Button></div>}
            </Toolbar>
          </div>
        </AppBar>
      </HideOnScroll>
      <div style={{height:"70px"}}></div>
      <Drawer state={stateDrawer} toggleDrawer={toggleDrawer} toggleDrawerLogin={toggleDrawerLogin}/>
      <LoginDrawer toggleLoginDrawerClose={toggleLoginDrawerClose} state={stateDrawerLogin}/>
    </React.Fragment>
  );
}
