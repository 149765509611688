import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Login from './Login';

const LoginDrawer = ({toggleLoginDrawerClose,state}) => {
    return (
        <React.Fragment >
          
          <Drawer
            anchor={"bottom"}
            open={state}
            onClose={toggleLoginDrawerClose}
          >
            <Box
              role="presentation"
              
            >
              <Login toggleLoginDrawerClose={toggleLoginDrawerClose}/>
            </Box>
          </Drawer>
      </React.Fragment>
    )
}

export default LoginDrawer
