import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import {
  getServiceByCode,
  getNamesImage,
} from "../../../service/api/serviceService";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';

import CarouselImages from "../../../components/general/CarouselImages";
import { getContactInfo } from "../../../service/api/ContactService";
import Grid from "@mui/material/Grid";


import { setClipboardText } from "../../../service/utils/funtions";
import { APP_EXPO } from "../../../service/KEYS";

import parse from 'html-react-parser';

export default function SimplePaper() {
  const { code } = useParams();
  const [service, setService] = useState({description:"<p></p>",price_id:{visible:false, totalPrice: 0}});
  const [images, setImages] = useState([]);
  const [contact, setContact] = useState({});
  const copyLinkService = () => {
    setClipboardText(`${APP_EXPO}/shared/service/${service.code}`);
  };
  useEffect(() => {
    const init = async () => {
      try {
        const res = await getServiceByCode(code);
        setService(res);
        const resImages = await getNamesImage(res.id);
        
        setImages(resImages);
        const info = await getContactInfo();
        setContact(info);
      } catch (error) { }
    };
    init();
  }, [code]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#eeeeee",
        width: "100%",
        paddingBottom: "50px",
      }}
    >
      <Paper
        elevation={12}
        sx={{ marginTop: "20px", marginInline: "10px", padding: "10px", minWidth:"300px" }}
      >
        <Typography variant="h4" gutterBottom component="div" fontWeight="bold" fontFamily="unset" sx={{color: "#1976d2"}}>
          {service.name}
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <CarouselImages images={images} />
        </div>
        <div style={{ marginTop: "10px" }}>
        {(service.price_id.visible === true) && <span
          style={{
            padding: "4px",
            borderRadius: "50%",
            fontWeight: "bold",
            color: "#1976d2",
            fontSize: "21px",
          }}
        >
          {service.price_id.totalPrice} Bs.
        </span>}
        </div>
        <Grid padding={2}>
        <Typography
          variant="subtitle1"
          
          sx={{ maxWidth: "700px" }}
          gutterBottom
          component="div"
        >
          {parse(service.description)}
        </Typography>
        </Grid>
        <Grid mt={2}>
            <Button
              variant="contained"
              sx={{ color: "#fff" }}
              disableElevation
              fontWeight="bold"
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=${contact.whatsapp}&text=${service.name}`}
            >
              Solicitar servicio<MiscellaneousServicesIcon />
            </Button>
        </Grid>

        <Grid mt={2}>
          <span style={{ /* background: "#cfe8fc", */ padding: "3px 3px 3px 0px", borderRadius: "20px", color: "#2962ff" }}>
            <Button
              variant="outlined"
              size="small"
              sx={{ borderRadius: "50%", background: "#cfe8fc" }}
              onClick={copyLinkService}
            >
              Copiar
            </Button>
            <p style={{fontSize:'10px'}}>  
            {` ${APP_EXPO}/shared/service/${service.code}`}
            </p>
          </span>
        </Grid>
      </Paper>
    </div>
  );
}
