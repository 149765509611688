import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../views/user/home/Home";
import ProductsViews from "../views/user/products/Products";
import FindProduct from "../views/user/products/FindProduct";

import ServiceViews from "../views/user/servicesdir/Services";
import FindService from "../views/user/servicesdir/FindService";

const UserRoutes = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/" name="Home" component={Home} />
        {/* PRODUCTOS */}
        <Route
          exact
          path="/all/invernaderos"
          name="Home"
          component={ProductsViews}
        />
        <Route
          exact
          path="/all/maquinarias"
          name="Home"
          component={ProductsViews}
        />
        <Route
          exact
          path="/all/laboratorios"
          name="Home"
          component={ProductsViews}
        />
        <Route exact path="/all/riegos" name="Home" component={ProductsViews} />

        <Route
          exact
          path="/shared/product/:code"
          name="findProduct"
          component={FindProduct}
        />
        {/* SERVICES */}
        <Route
          exact
          path="/service/construnccion"
          name="Home"
          component={ServiceViews}
        />
        <Route
          exact
          path="/service/instalacion"
          name="Home"
          component={ServiceViews}
        />
        <Route
          exact
          path="/service/cursos"
          name="Home"
          component={ServiceViews}
        />
        <Route
          exact
          path="/service/asesorias"
          name="Home"
          component={ServiceViews}
        />
        <Route
          exact
          path="/service/laboratorio"
          name="Home"
          component={ServiceViews}
        />

        <Route
          exact
          path="/shared/service/:code"
          name="findService"
          component={FindService}
        />
      </Switch>
    </div>
  );
};

export default UserRoutes;
