import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import {
  getProductByCode,
  getNamesImage,
} from "../../../service/api/productService";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import CarouselImages from "../../../components/general/CarouselImages";
import { getContactInfo } from "../../../service/api/ContactService";
import Grid from "@mui/material/Grid";

import { setClipboardText } from "../../../service/utils/funtions";
import { APP_EXPO } from "../../../service/KEYS";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import BtnBack from "../../../components/general/BtnBack";

import parse from "html-react-parser";

const outerTheme = createTheme({
  palette: {
    secondary: {
      main: "#22c33f",
    },
  },
});

export default function SimplePaper() {
  const { code } = useParams();
  const [product, setProduct] = useState({
    description: "<p></p>",
    price_id: { visible: false, totalPrice: 0 },
    category_id:{
      urlpath:""
    }
    
  });
  const [images, setImages] = useState([]);
  const [contact, setContact] = useState({});
  const copyLinkProduct = () => {
    setClipboardText(`${APP_EXPO}/shared/product/${product.code}`);
  };
  useEffect(() => {
    const init = async () => {
      try {
        const res = await getProductByCode(code);
        setProduct(res);
        const resImages = await getNamesImage(res.id);
        setImages(resImages);
        const info = await getContactInfo();
        setContact(info);
      } catch (error) {}
    };
    init();
  }, [code]);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: "#eeeeee",
        width: "100%",
        paddingBottom: "50px",
      }}
    >
      <Paper
        elevation={12}
        sx={{ marginTop: "20px", marginInline: "10px", padding: "10px", minWidth:"300px" }}
      >
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          sx={{ color: "#616161" }}
          fontWeight="bold"
          fontFamily="unset"
        >
          {product.name}
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <CarouselImages images={images} />
        </div>
        <div style={{ marginTop: "10px" }}>
          {product.price_id.visible === true && (
            <span
              style={{
                padding: "5px",
                borderRadius: "50%",
                fontWeight: "bold",
                color: "#616161",
                fontSize: "22px",
              }}
            >
              {product.price_id.totalPrice} Bs.
            </span>
          )}
        </div>
        <Grid padding={2}>
        <Typography
          variant="subtitle1"
          gutterBottom
          component="div"
        >
          {parse(product.description)}
        </Typography>
        </Grid>
        <Grid mt={2}>
          <ThemeProvider theme={outerTheme}>
            <Button
              color="secondary"
              variant="contained"
              sx={{ color: "#fff",margin:"5px" }}
              disableElevation
              fontWeight="bold"
              target="_blank"
              href={`https://api.whatsapp.com/send?phone=${contact.phone}&text=${product.name}`}
            >
              Realizar pedido <ShoppingCartIcon />
            </Button>
          </ThemeProvider>

        
          <BtnBack urlback={product.category_id.urlpath}/>
        </Grid>

        <Grid mt={2}>
          <span
            style={{
              /* background: "#cfe8fc", */ padding: "3px 3px 3px 0px",
              borderRadius: "20px",
              color: "#2962ff",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{ borderRadius: "50%", background: "#cfe8fc" }}
              onClick={copyLinkProduct}
            >
              Copiar
            </Button>
            <p style={{fontSize:'10px'}}>
            {` ${APP_EXPO}/shared/product/${product.code}`}
            </p>
          </span>
        </Grid>
      </Paper>
    </div>
  );
}
