import React from "react";
import ServicesViews from "./ServicesViews";
import PaginationState from "../../../contex/servicePagination/PaginationState";
const Services = () => {
  return (
    <PaginationState>
      <ServicesViews />
    </PaginationState>
  );
};

export default Services;
