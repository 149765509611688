import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import CardProduct from './CardProduct'

const ListProducts = ({products, pathname, refSeeMore}) => {
    useEffect(() => {
        console.log("Render products", products)
    }, [products,  pathname])
    return (
        <>
            { products.map((product, index) => (
                <Grid key={index} align="center" item xs={6} sm={6} md={3}>
                    <CardProduct
                        key={index}
                        product={product}
                        statusNavigation={pathname}
                    />
                    {( products?.length > 6 && index === (products.length - 6 )) && <p style={{background: 'red'}} ref={refSeeMore} ></p>  }
                </Grid>
            ))}
        </>
    )
}

export default ListProducts
