import API from "../apiConection";
import { createHeader } from "../KEYS";

export async function postProduct(data, token) {
  const header = createHeader(token);
  try {
    const res = await API.post("/products", data, header);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
export async function updateProduct(id,data, token) {
  const header = createHeader(token);
  try {
    const res = await API.put(`/products/${id}`, data, header);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}




/**
 * available puede ser: "", "/available","/noavailable"
 */
export async function allProductsByCategory(
  categoryId,
  token,
  available = "",
  page = 0,
  size
) {
  const header = createHeader(token);
  try {
    const res = await API.get(
      `/products/category${available}/${categoryId}?page=${page}&size=${size}&order_by=created_at&order_direction=desc`,
      header
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
export async function allProductsPublic(categoryId,page,size=12) {
  try {
    const res = await API.get(
      `/products/category/a/${categoryId}?page=${page}&size=${size}&order_by=created_at&order_direction=desc`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
export async function deleteProductById(id, token) {
  const header = createHeader(token);
  try {
    const res = await API.delete(`/products/${id}`, header);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getProductById(id, token) {
  const header = createHeader(token);
  try {
    const res = await API.get(`/products/${id}`, header);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
/**IMAGES */

export async function sendImages(id, formData, token) {
  const config = {
    headers: { "x-access-token": token, "Content-Type": "multipart/form-data" },
  };

  try {
    const res = await API.post(
      "/products/images/upload/" + id,
      formData,
      config
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getNamesImage(id, token) {

  try {
    const res = await API.get(`/products/images/names/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
export async function getProductByCode(code) {

  try {
    const res = await API.get(`/products/c/${code}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export async function deleteImageByPublicname(publicname, token) {
  const header = createHeader(token);
  try {
    const res = await API.delete(`/products/images/${publicname}`, header);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}
