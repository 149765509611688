
import Leaflet from 'leaflet'
import Icon from '../../../assets/marcador.png'
export const IconLocation = Leaflet.icon({
    iconUrl: Icon,
    shadowRetinaUrl: Icon,
    iconSize:     [30, 35], // size of the icon
    shadowSize:   [0, 0], // size of the shadow
    iconAnchor:   [0, 0], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor:  [0, 0],
    ClassName: "leaflet-venue-icon",
});