import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import "./BtnFlotante.css";

const BtnBack = ({ urlback }) => {
  const history = useHistory();
  return (
    <div className="containerBtn">
      <Button
        color="secondary"
        variant="outlined"
        fontWeight="bold"
        onClick={() => {
          history.push(urlback);
        }}
      >
        Atras
      </Button>
    </div>
  );
};

export default BtnBack;
