export const scrollId =(id) =>{
    
    try {
        if(id!==null){
            document.getElementById(id).scrollIntoView();
        }
    } catch (error) {
        
    }
   
}
export const setClipboardText = (text)=>{
    var id = "el-id-del-textarea";
    var existsTextarea = document.getElementById(id);

    if(!existsTextarea){
        var textarea = document.createElement("textarea");
        textarea.id = id;
        // Coloca el textarea en el borde superior izquierdo
        textarea.style.position = 'fixed';
        textarea.style.top = 0;
        textarea.style.left = 0;

        // Asegurate que las dimensiones del textarea son minimas, normalmente 1px 
        // 1em no funciona porque esto generate valores negativos en algunos exploradores
        textarea.style.width = '1px';
        textarea.style.height = '1px';

        // No se necesita el padding
        textarea.style.padding = 0;

        // Limpiar bordes
        textarea.style.border = 'none';
        textarea.style.outline = 'none';
        textarea.style.boxShadow = 'none';

        // Evitar el flasheo de la caja blanca al renderizar
        textarea.style.background = 'transparent';
        document.querySelector("body").appendChild(textarea);
        existsTextarea = document.getElementById(id);
    }else{

    }

    existsTextarea.value = text;
    existsTextarea.select();

    try {
        var status = document.execCommand('copy');
        if(!status){
            
        }else{
            
        }
    } catch (err) {
        
    }
}