import React from "react";
import { AppBar, Container, Toolbar } from "@mui/material";
import Copyringht from "../../admin/Copyright";
/* import Link from "@mui/material/Link";
import navUser from "../../../components/appbar/_navUser";
import Grid from "@mui/material/Grid"; */
import { makeStyles } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
const useStyle = makeStyles({
  footerStyle: {
    minHeight: "200px",
    display: "flex",
    justifyContent: "end",
    background: "linear-gradient(90deg, #1161D1 0%, #599BF7 100%)",
  },
  linkStyle: {
    color: "#fff",
    margin: "5px 5px 2px 5px",
    textTransform: "uppercase",
    fontSize: "8px",
  },
});
const Footer = () => {
  const classes = useStyle();
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <AppBar className={classes.footerStyle} position="static" color="primary">
      <Container maxWidth="md">
        <Toolbar sx={{ display: "flex", flexDirection: "column" }}>
          {/* <Grid container spacing={1} columns={{ xs: 3, sm: 5, md: 7 }}>
            {navUser.map((option, index) => {
              return (
                <Fragment>
                  {!option.items && (
                    <Grid key={option.name} item xs={1} sm={1} md={1}>
                      <Link
                        key={option.name}
                        sx={{
                          color: "#fff",
                          margin: "5px 5px 2px 5px",
                          textTransform: "uppercase",
                          fontSize: matches ? "10px" : "8px"
                        }}
                        href={option.to}
                        underline="hover"
                      >
                        {option.name}
                      </Link>
                    </Grid>
                  )}
                </Fragment>
              );
            })}
          </Grid> */}
          <Copyringht
            sx={{ color: "#fff", fontSize: matches ? "11px" : "9px" }}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;
