import React,{useState} from 'react';
import PaginationContext from './PaginationContext';


const PaginationState = (props) => {

    const [numPage,setNumPage] = useState(0);
    const [total,setTotal] = useState(0);
    const [typeService,setTypeService] = useState(0);

    const updateNumPage = (numeroPage) =>{
        setNumPage(numeroPage);
    }
    const updateTotal = (totalAny) =>{
        setTotal(totalAny);
    }

    const updateTypeService = (typeService) =>{
        setTypeService(typeService)
    }

    const refrest = () =>{
        setNumPage(0);
        setTotal(0);
    }



    return (
        <PaginationContext.Provider
            value={{
                numPage,
                updateNumPage,
                total,
                updateTotal,
                refrest,
                typeService,
                updateTypeService
            }}
        >
            {props.children}
        </PaginationContext.Provider>
    )
}

export default PaginationState
