import { useEffect, useState } from "react";
import { allProductsPublic } from "../../../../service/api/productService";
import { CATEGORY_PRODUCTS } from "../../../../components/appbar/_navUser";

export const useFetchProduct = (pathname, setCategoryProduct) => {
    const [products, setProducts] = useState([]);
    const [statusProgress, setStatusProgress] = useState(false);
    const [numPage, setNumPage] = useState(0);
    const [seeMore, setSeeMore] = useState(true);


    const getProducts = async (categoryProductId, numberPage = 0, numRows = 8) => {
        setStatusProgress(true);
        try {
            if (numberPage > -1) {
                setSeeMore(true)
                let res = await allProductsPublic(categoryProductId, numberPage, numRows);
                if (numberPage > 0) {
                    setProducts((prev) => [...prev, ...res.content]);
                } else {
                    setProducts(res.content)
                }
                setNumPage(res.nextPage);
                if (res.nextPage == -1) setSeeMore(false);
            }
        } catch (error) {
        }
        setStatusProgress(false);
    }

    const initialData = () => {
        try {
          const categoryProdut = CATEGORY_PRODUCTS.find(product => product.to === pathname)
          if (categoryProdut) {
            setCategoryProduct(categoryProdut)
            getProducts(categoryProdut.id)
          }
        } catch (error) {
        }
      }
    
      useEffect(() => {
        initialData();
      }, [pathname])

    return {
        products,
        statusProgress,
        getProducts,
        numPage,
        seeMore
    }
}