import React from "react";
import ProductsViews from "./ProductsViews";
import PaginationState from "../../../contex/productPagination/PaginationState";
const Products = () => {
  return (
    <PaginationState>
      <ProductsViews />
    </PaginationState>
  );
};

export default Products;
