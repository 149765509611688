import React, { useReducer,useState } from "react";
import API from "../../service/apiConection";

import UserContext from "./UserContext";
import UserReducer from "./UserReducer";

import { REGISTER, LOGIN, LOGIN_ID, LOGOUT } from "../types";

const UserState = (props) => {
    const initialState = {
        user:{
            token:"",
            user:{
                role:[],
                url_img:""
            }
        }
    };
    const [message, setMessage] = useState("");
    const [state, dispatch] = useReducer(UserReducer, initialState);
    const [online, setOnline] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminArea, setAdminArea] = useState(false);
    
    const updateDataUser = async (data) =>{
        dispatch({type:LOGIN,payload: data});
        setIsAdmin(false);
        setOnline(false);
    }
    const signin =  async(userAcces)=>{
        try {
            const user = await API.post('/auth/signin',userAcces);
            const data = user.data;
            if(user.data.message){
                setMessage(user.data.message);
                return "SMS";
            }else{
                dispatch({type:LOGIN,payload: data});
                setOnline(true);
                if(data.user.roles[0].name ==='admin'){
                    setIsAdmin(true);
                }
            }
        } catch (error) {
            return "NOCONECT";
        }
    }

    /* const signup = async(newUser)=>{
        try {
            const user = await API.post('/auth/signup',newUser);
            const data = user.data;
            
            if(user.data.message){
                setMessage(user.data.message);
                return user.data.message;
            }else{
                dispatch({type:REGISTER,payload: data});
                setOnline(true);
                setIsUser(true);
            }
        } catch (error) {
            return "NOCONECT";
        }
    } */
    const logout = ()=>{
        const data = {
            user:{
                token:"",
                user:{
                    role:[],
                    url_img:""
                }
            }
          };
        setOnline(false);
        dispatch({type:LOGOUT,payload: data});
        setIsAdmin(false);
        setIsUser(false);
    }

return (
    <UserContext.Provider
      value={{
        userAll:state.user,
        user: state.user.user,
        token:state.user.token,
        isAdmin,
        isUser,
        online,
        message,
        setMessage,
        signin,
        logout,
        updateDataUser,
        adminArea,
        setAdminArea
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
