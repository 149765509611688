import React, { useState, useEffect, Fragment, useRef } from "react";
import Footer from "../footer/Footer";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";
import ListProducts from "./ListProducts";
import { useFetchProduct } from "./hooks/Products";
import GradientCircularProgress from "./GradientProgress";

const ProductsViews = () => {
  const [categoryProduct, setCategoryProduct] = useState({
    name: "",
    id: undefined,
  });
  const location = useLocation();
  const { products, statusProgress, getProducts, numPage, seeMore } =
    useFetchProduct(location.pathname, setCategoryProduct);

  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersection);
    if (observer && elementRef.current) {
      console.log("CURREN", elementRef.current);
      observer.observe(elementRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [location.state, location.pathname, numPage]);

  const onIntersection = async (entries) => {
    const firstEntry = entries[0];
    if (firstEntry.isIntersecting) {
      await getProducts(categoryProduct.id, numPage);
    }
  };

  return (
    <Fragment>
      <div
        id="prodtucts-help-scroll-top"
        style={{ background: "#cfe8fc" }}
      ></div>
      <Grid
        sx={{ bgcolor: "#eeeeee", width: "100%" }}
        container
        alignItems="center"
        rowSpacing={3}
        columns={12}
        columnSpacing={{ xs: 1, sm: 1, md: 1, mt: 2 }}
      >
        <Grid item xs={12} sm={4} md={12} margin={2} align="center">
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#2f3542",
            }}
            variant="h4"
            component="h1"
          >
            {categoryProduct.name}
          </Typography>
        </Grid>

        {statusProgress && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}

        <ListProducts
          products={products ? products : []}
          pathname={location.pathname}
          refSeeMore={elementRef}
        />

        {seeMore && (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <GradientCircularProgress />
          </div>
        )}
      </Grid>

      <Footer />
    </Fragment>
  );
};

export default ProductsViews;
