import React, { Fragment, useContext, useEffect } from 'react';

import SwitImages from './SwitImges';
import UserContext from '../../../contex/user/UserContext';
import Footer from '../footer/Footer';

const Home = () => {
  const { adminArea, setAdminArea } = useContext(UserContext);
  useEffect(() => {
    const initial = () => {
      if (adminArea) {
        setAdminArea(false);
      }
    }
    initial()
  }, [adminArea,setAdminArea])
  return (
    <Fragment>
      
      <SwitImages />
      <Footer/>
    </Fragment>
  )
}

export default Home
